import React, { useContext } from 'react';
import { Button, Menu } from 'antd';
import {
  CalendarOutlined, ControlOutlined, DashboardOutlined, DribbbleOutlined,
  HomeOutlined, IdcardOutlined
  , LogoutOutlined, MenuOutlined, TrophyOutlined
} from '@ant-design/icons';
// import '../layout/layout.css';
import { Link, useLocation } from 'react-router-dom';
import { MenuContext } from 'react-flexible-sliding-menu';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/utils';

const UserMenu = (props) => {
  const location = useLocation();
  const { closeMenu } = useContext(MenuContext);
  const { t } = useTranslation();
  return (
    <>
      <Menu theme="dark" mode={props.mode || 'inline'} defaultSelectedKeys={['/user']} selectedKeys={[location.pathname]} onClick={closeMenu}>
        {props.mode !== 'horizontal'
          ? (
            <Button onClick={closeMenu} style={{ height: '100%', width: '100%', border: 0, display: 'flex', justifyContent: 'flex-end' }} ghost ><MenuOutlined style={{ fontSize: 32, margin: '8px' }} /></Button>
          )
          : (<></>)}
        {props.center?.plan[0] === '1' && (
          <>
            <Menu.Item key="/user" icon={<HomeOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/user">{capitalize(t('home'))}</Link>
            </Menu.Item>
            <Menu.Item key="/user/profile" icon={<IdcardOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/user/profile'>{capitalize(t('myProfile'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[1] === '1' && (
          <>
            <Menu.Item key="/user/exercises" icon={<DribbbleOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/user/exercises'>{capitalize(t('exercises'))}</Link>
            </Menu.Item>
            <Menu.Item key="/user/programs" icon={<ControlOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/user/programs'>{capitalize(t('programs'))}</Link>
            </Menu.Item>
            <Menu.Item key="/user/strength" icon={<TrophyOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/user/strength'>{capitalize(t('strengthTest'))}</Link>
            </Menu.Item>
            <Menu.Item key="/user/weight" icon={<DashboardOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/user/weight'>{capitalize(t('weight'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[2] === '1' && (
          <Menu.Item key="/user/booking" icon={<CalendarOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
            <Link to='/user/booking'>{capitalize(t('booking'))}</Link>
          </Menu.Item>
        )}
        {props.mode !== 'horizontal'
          ? (
            <Menu.Item key="logout" icon={<LogoutOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/logout'>{capitalize(t('logout'))}</Link>
            </Menu.Item>
          )
          : (<></>)}
      </Menu>
    </>
  );
};

export default UserMenu;
