import createDataContext from './CreateDataContext';
import { methodPost } from '../api/methods';
import Cookies from 'js-cookie';

const authReducer = (state, action) => {
  switch (action.type) {
  case 'signout':
    return { token: null, user: null };
  case 'signin':
  case 'signup':
    return {
      token: action.payload.token,
      account: action.payload.user
    };
  default:
    return state;
  }
};

const signin = dispatch => {
  return async ({ email, password }) => {
    // Do some API Request here
    const loginPayload = {
      username: email,
      password: password
    };
    const loginData = await methodPost('auth/login', loginPayload);
    if (loginData.data.statusCode === 401) {
      return 'Usuario o contraseña incorrectos';
    }
    console.log('loginData', loginData);

    dispatch({
      type: 'signin',
      payload: {
        token: loginData.data.token,
        account: loginData.data.user
      }
    });
    Cookies.set('access', loginData.data.token);
    Cookies.set('account', JSON.stringify(loginData.data.user));
  };
};

const signout = dispatch => {
  return () => {
    dispatch({ type: 'signout' });
    window.location.replace('/');
    Cookies.remove('access');
    Cookies.remove('account');
  };
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signin, signout },
  { token: null, account: null }
);
