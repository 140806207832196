import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { Col, Row } from 'antd';
import { getIdToken } from 'firebase/auth';
import { getAccountByQuery } from '../../../services/user.service';
import { getCenterByQuery } from '../../../services/center.service';
import ResumeDashboard from '../../../components/cards/CardResumeDashboard';
import { HomeOutlined, TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import { capitalizeAll } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

const DashboardAdminHome = (props) => {
  const { user } = useContext(Auth);
  // const [devices, setDevices] = useState([]);
  const [centers, setCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  // const [programSessions, setProgramSessions] = useState([]);

  useEffect(() => {
    if (user.account) {
      console.log('user', user);
      loadUsers();
    }
  }, [user.account]);

  //
  // const loadDevices = async (centerId) => {
  //   const { status, data } = await getDeviceByQuery({ center: centerId, isActive: true }, user.token);
  //   if (status === 200) {
  //     data.data.forEach(device => {
  //       device.key = device._id;
  //     });
  //     setDevices(data.data);
  //   }
  // };
  //
  const loadUsers = async () => {
    const { status, data } = await getAccountByQuery({ roles: 'user', isActive: true }, user.token);
    if (status === 200) {
      await loadCenters();
      await loadOrganizers();
      setUsers(data.data);
      setLoading(false);
    }
  };

  const loadCenters = async () => {
    const { status, data } = await getCenterByQuery({ isActive: true }, user.token);
    if (status === 200) {
      setCenters(data.data);
    }
    setLoading(false);
  };

  const loadOrganizers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'organizer', isActive: true }, user.token);
    if (status === 200) {
      setOrganizers(data.data);
    }
  };

  // const renderDevice = (device) => {
  //   const url = process.env.REACT_APP_URL;
  //   const link = `/device/${center._id}/${device.identifier}`;
  //   return (
  //     <Row style={{ marginBottom: 12, alignItems: 'center' }}>
  //       <Col xs={24} lg={12}>
  //         {device.name} [{device.identifier}]
  //       </Col>
  //       <Col xs={24} lg={12} style={{ textAlign: 'right' }}>
  //         <Link type="primary" style={{ marginRight: 5 }} to={link} target="_blank">Go to URL</Link>
  //         <CopyToClipboard text={url + link}
  //                          onCopy={() => message.success('URL copied successfully')}>
  //           <Button><CopyOutlined /></Button>
  //         </CopyToClipboard>
  //       </Col>
  //     </Row>
  //   );
  // };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      <Row justify='center' style={{ gap: 15, marginBottom: 25 }}>
        <ResumeDashboard title={capitalizeAll(t('users'))} icon={<TeamOutlined />} value={users.length}/>
        <ResumeDashboard title={capitalizeAll(t('organizers'))} icon={<UserAddOutlined />} value={organizers.length}/>
        <ResumeDashboard title={capitalizeAll(t('centers'))} icon={<HomeOutlined />} value={centers.length}/>
        {/* <ResumeDashboard title={'Earnings'} icon={<DollarOutlined />} value={'0€'}/> */}
      </Row>
      <Row>
        <Col xs = {24} md={12}>
          <p><b></b></p>
          {/* {devices.map(device => renderDevice(device))} */}
          <br />
        </Col>
      </Row>

    </>
  );
};

export default DashboardAdminHome;
