import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, message, Popconfirm } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { createCenter, getCenterById, patchCenter } from '../../../services/center.service';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateCenter = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [error, setError] = useState(null);
  const [center, setCenter] = useState(null);
  const [organizers, setOrganizers] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { Option } = Select;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    loadOrganizers();
  }, []);

  const loadOrganizers = async () => {
    const { status, data } = await getAccountByQuery({ roles: 'organizer' }, user.token);
    if (status === 200) {
      setOrganizers(data.data);
      if (params.id) {
        await loadCenter();
      }
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterById(params.id, user.token);
    if (status === 200) {
      setCenter(data.data);
      fillForm(data.data);
    }
  };

  const deactivateCenter = async () => {
    if (params.id) {
      await patchCenter(params.id, { isActive: false }, user.token);
      message.success(capitalizeAll(t('centerDeleted')));
      navigate('/dashboard/centers');
    }
  };

  const fillForm = (center) => {
    if (center) {
      form.setFieldsValue({
        name: center.name,
        email: center.email,
        phone: center.phone,
        address: center.address,
        CIF: center.cif,
        description: center.description,
        plan: center.plan,
        organizer: center.organizer,
        theme: center.theme
      });
    }
  };

  const createNewCenter = async (data, token) => {
    const formData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      description: data.description,
      cif: data.CIF,
      plan: data.plan,
      organizer: data.organizer,
      theme: data.theme ? data.theme : ''
    };
    let newCenter;
    if (params.id) {
      newCenter = await patchCenter(params.id, formData, token);
    } else {
      newCenter = await createCenter(formData, token);
    }
    navigate('/dashboard/centers');
    return newCenter;
  };

  const correctClave = async e => {
    if (params.id) {
      await createNewCenter(e, user.token);
    } else {
      const { status, data } = await createNewCenter(e, user.token);
      if (status === 200) {
        navigate('/dashboard/centers');
      } else {
        setError(status);
      }
    }
  };

  if (loading) {
    return <Spinner/>;
  } else {
    return (
      <div
        style={{
          background: '#fff',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Form form={form} onFinish={correctClave} style={{
          width: '100%',
          maxWidth: 400
        }}>
          <Form.Item>
            {center ? <h1>{capitalizeAll(t('editCenter'))}</h1> : <h1>{capitalizeAll(t('createCenter'))}</h1>}
            {error ? (<>{error}</>) : (<></>)}
          </Form.Item>
          <Form.Item
            name='name'
            rules={[
              {
                required: true,
                message: capitalize(t('name')) + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={capitalize(t('name'))}
              name="name"
              defaultValue={center ? center.name : ''}
              placeholder={capitalize(t('name'))}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: capitalize(t('email')) + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={capitalize(t('email'))}
              name="email"
              defaultValue={center ? center.email : ''}
              placeholder={capitalize(t('email'))}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: capitalize(t('phone')) + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={capitalize(t('phone'))}
              name="phone"
              defaultValue={center ? center.phone : ''}
              placeholder={capitalize(t('phone'))}
            />
          </Form.Item>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: capitalize(t('address')) + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={capitalize(t('address'))}
              name="address"
              defaultValue={center ? center.address : ''}
              placeholder={capitalize(t('address'))}
            />
          </Form.Item>
          <Form.Item
            name="CIF"
            rules={[
              {
                required: true,
                message: t('cif').toUpperCase() + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={t('cif').toUpperCase()}
              name="CIF"
              defaultValue={center ? center.cif : ''}
              placeholder={t('cif').toUpperCase()}
            />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: capitalize(t('description')) + ' ' + t('isRequired')
              }
            ]}>
            <Input
              addonBefore={capitalize(t('description'))}
              name="description"
              defaultValue={center ? center.description : ''}
              placeholder={capitalize(t('description'))}
            />
          </Form.Item>

          <div style={{ textAlign: 'left' }}>
            {capitalize(t('plan'))}:
          </div>
          <Form.Item
            name="plan"
            rules={[
              {
                required: true,
                message: capitalize(t('plan')) + ' ' + t('isRequired')
              }
            ]}>
            <Select defaultValue={center ? center.plan : '0000000000'} >
              <Option value="0000000000">{capitalizeAll(t('selectPlan'))}</Option>
              <Option value="1000000000">Solo usuarios</Option>
              <Option value="1100000000">Usuarios + Hist</Option>
              <Option value="1110000000">Usuarios + Hist + Reservas</Option>
              <Option value="1010000000">Usuarios + Reservas</Option>
            </Select>
          </Form.Item>
          <div style={{ textAlign: 'left' }}>
            {capitalize(t('theme'))}:
          </div>
          <Form.Item
            name="theme"
            rules={[
              {
                required: false,
                message: capitalize(t('theme')) + ' ' + t('isRequired')
              }
            ]}>
            <Select defaultValue={center ? center.plan : ''} >
              <Option value="">{capitalize(t('dark'))}</Option>
              <Option value="-light">{capitalize(t('light'))}</Option>
            </Select>
          </Form.Item>
          <div style={{ textAlign: 'left' }}>
            {capitalize(t('organizer'))}:
          </div>
          <Form.Item
            name="organizer"
            rules={[
              {
                required: true,
                message: capitalize(t('organizer')) + ' ' + t('isRequired')
              }
            ]}>
            <Select defaultValue={center ? center.organizer : 'null'} >
              <Option value="null">{capitalizeAll(t('selectOrganizer'))}</Option>
              {organizers.map((org, key) => {
                return (
                  <Option key={key} value={org._id}>{org.name}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
            {center && (
              <Popconfirm
                title={capitalize(t('confirmDeleteCenter'))}
                onConfirm={deactivateCenter}
                // onCancel={cancel}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    className="delete-form-button"
                    style={{ marginRight: 10 }}
                    // onClick={deactivateUser}
                  >
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              </Popconfirm>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ marginRight: 10 }}
              >
                {center ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createCenter'))}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
};
export default CreateCenter;
