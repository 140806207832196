import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../services/auth/AuthContext';
import Spinner from './Spinner';
import AdminMenu from './AdminMenu';
import OrganizerMenu from './OrganizerMenu';
import TrainerMenu from './TrainerMenu';
import UserMenu from './UserMenu';
import async from 'async';
import { getCenterById, getCenterByQuery } from '../../services/center.service';
import { getIdToken } from 'firebase/auth';

const TopMenu = (props) => {
  const { user, signout } = useContext(Auth);
  const [center, setCenter] = useState(null);
  const [menu, setMenu] = useState(null);

  console.log('user', user);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      return data.data[0];
    }
  };

  const accountSignOut = async () => {
    await signout();
    // const auth = getAuth(app);
    // await signOut(auth).then((response) => {
    // });
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      return data.data;
    }
  };

  console.log('center', center);

  useEffect(async () => {
    if (!user.account) {
      return (<Spinner/>);
    } else {
      if (user.account.roles.indexOf('admin') >= 0) {
        setMenu(<AdminMenu signOut={accountSignOut} mode={props.mode}/>);
      } else if (user.account.roles.indexOf('organizer') >= 0) {
        const center = await loadCenter();
        setMenu(<OrganizerMenu signOut={accountSignOut} mode={props.mode} center={center}/>);
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        const center = await loadCenterId();
        setMenu(<TrainerMenu signOut={accountSignOut} mode={props.mode} center={center}/>);
      } else if (user.account.roles.indexOf('user') >= 0) {
        const center = await loadCenterId();
        setMenu(<UserMenu signOut={accountSignOut} mode={props.mode} center={center}/>);
      }
    }
  }, [user.account]);

  if (!user.account && menu) {
    return (<Spinner/>);
  } else {
    return (
      <>
        {menu || (<></>)}
      </>
    );
  }
};

export default TopMenu;
