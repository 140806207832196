import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import {
  getExerciseSessionById,
  getExerciseSessionByQuery
} from '../../../services/exercisesession.service';
import { getExerciseById, getExerciseByQuery } from '../../../services/exercise.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Button, Select, Table } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getAccountByQuery } from '../../../services/user.service';
import { useReactToPrint } from 'react-to-print';
import { ArrowDownOutlined, ArrowUpOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import * as xlsx from 'xlsx-js-style';

const Set = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'CIEX'
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: false,
          text: '.'
        },
        ticks: {
          stepSize: 10
        }
      },
      x: {
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: () => null // or function () { return null; }
        }
      }
    }
  };
  const renderSet = (set, i) => {
    const labels = set.stats.labels;
    const data = {
      labels,
      datasets: [
        {
          id: 1,
          label: 'Media',
          borderColor: 'rgba(255, 99, 132,1)',
          backgroundColor: 'rgba(255, 99, 132,0.5)',
          fill: false,
          data: set.stats.datasets.media
        }
        // {
        //   id: 2,
        //   label: '2',
        //   borderColor: 'rgba(255, 99, 132,1)',
        //   backgroundColor: 'rgba(255, 99, 132,0.5)',
        //   fill: false,
        //   data: exercises[1].sets[1].stats.datasets.right
        // }
      ]
    };

    return (
      <>
        <div>
          <h2 style={{ marginTop: 10, marginBottom: 0 }}>{capitalizeAll(props.t('set'))} {set.set}</h2>
          <div><b>{capitalizeAll(props.t('time'))}</b> {set.time}{'\'\''}</div>
          <div><b>{capitalizeAll(props.t('percent'))}</b> {set.percent}{'%'}</div>
          <div style={{ height: 230, marginTop: 10 }}>
            <Line
              datasetIdKey='id'
              data={data}
              options={options}
            />
          </div>
          <div className={'set-stats'}>
            <div>
              <b>{capitalize(props.t('max'))}</b> <i>{capitalize(props.t('media'))}</i>: {set.stats.datasets.mediaMax}
            </div>
            <div>
              <b>{capitalize(props.t('avg'))}</b> <i>{capitalize(props.t('media'))}</i>: {set.stats.datasets.mediaAvg}
            </div>
            <div>
              <b>{capitalize(props.t('min'))}</b> <i>{capitalize(props.t('media'))}</i>: {set.stats.datasets.mediaMin}
            </div>
          </div>
          {/* <Table columns={columns} dataSource={set} onChange={onChange}/> */}
          <br />
        </div>
      </>
    );
  };

  const renderStats = (e) => {
    const exercise = props.centerExercises.find((exercise) => exercise._id === e.exercise);
    const user = props.accounts.find((account) => account._id === e.account);
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button onClick={handlePrint} style={{ width: 30, height: 30 }}><PrinterOutlined style={{ fontSize: '24px', color: '#000' }} /></button>
        </div>
        <div ref={componentRef}>
          <h3>{exercise && exercise.name}</h3>
          <div className='exercise-info-stats'>
            <div>
              <b>{capitalizeAll(props.t('user'))}</b>: {user.name} {user.surname}<br/>
              <b>{capitalize(props.t('chair'))}</b>: {exercise.chair}<br/>
              <b>{capitalize(props.t('backChair'))}</b>: {exercise.back}<br/>
              <b>{capitalize(props.t('sensors'))}</b>: {exercise.sensors}<br/>
              <b>{capitalize(props.t('mode'))}</b>: {exercise.mode}<br/>
            </div>
            <div>
              <b>{capitalize(props.t('date'))}</b>: {moment(e.timestamp.createdAt).format('DD-MM-YYYY HH:mm')}<br/>
              <b>{capitalize(props.t('apost'))}</b>: {exercise.ap}<br/>
              <b>{capitalize(props.t('lm'))}</b>: {exercise.lm}<br/>
              <b>{capitalize(props.t('lg'))}</b>: {exercise.lg}<br/>
              <b>{capitalize(props.t('handleVert'))}</b>: {exercise.handleVert}<br/>
              <b>{capitalize(props.t('handleHoriz'))}</b>: {exercise.handleHoriz}<br/>
            </div>
          </div>
          {e.sets.map((set, i) => renderSet(set, i))}
        </div>

      </>
    );
  };

  return (
    <>
      {renderStats(props.exerciseSession)}
    </>
  );
};

const Charts = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [exerciseSessions, setExerciseSessions] = useState([]);
  const [center, setCenter] = useState(null);
  const [centerExercises, setCenterExercises] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountExercises, setAccountExercises] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [exerciseSessionResume, setExerciseSessionResume] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [setColumns, setSetColumns] = useState([]);
  const [tempRecord, setTempRecord] = useState(null);
  const { t } = useTranslation();
  const { Option } = Select;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'CIEX'
  });

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (center) {
      loadAccounts(center._id);
      loadCenterExercises(center._id);
    }
  }, [center]);

  useEffect(() => {
    if (user.account && accounts.length > 0 && centerExercises.length > 0) {
      setLoading(false);
    }
  }, [accounts, centerExercises]);

  const loadExerciseSessions = async (accountId) => {
    const { status, data } = await getExerciseSessionByQuery({ account: accountId }, user.token);
    if (status === 200) {
      const exercises = [];
      data.data.forEach((exercise) => {
        if (exercises.indexOf(exercise.exercise) < 0) {
          exercises.push(exercise.exercise);
        }
      });
      const account = accounts.find((account) => account._id === accountId);
      const accountExercises = {
        account,
        exercises
      };
      setAccountExercises(accountExercises);
      resetExercise();
      setExerciseSessions(data.data);
    }
  };

  const resetExercise = () => {
    setSelectedExercise(null);
    setSetColumns([]);
    setExerciseSessionResume([]);
    // set exercises select with id exercise placeholder to select exercise
    const select = document.getElementById('exercise');
    select.value = '';
  };

  const loadCenterExercises = async (centerId) => {
    const { status, data } = await getExerciseByQuery({ center: centerId }, user.token);
    if (status === 200) {
      setCenterExercises(data.data);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
    }
  };

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId }, user.token);
    if (status === 200) {
      setAccounts(data.data);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  if (loading) {
    return <Spinner/>;
  }

  const columnsAccounts = [
    {
      title: capitalize(t('exercise')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: '_id',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.timestamp.createdAt}
          <br />
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['timestamp.createdAt'],
      key: 'timestamp.createdAt',
      align: 'center',
      render: (text, record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY HH:mm'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('name')),
      align: 'center',
      // dataIndex: ['name'],
      key: '_id',
      // render: (text, record) => record.name,
      render: (text, record) => selectedAccount.name + ' ' + selectedAccount.surname,
      responsive: ['sm']
    },
    {
      title: capitalize(t('exercise')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'exercise',
      // render: (text, record) => record.name,
      render: (text, record) => {
        const exercise = centerExercises.find((exercise) => exercise._id === record.exercise);
        return exercise.name;
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sets')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'setNumber',
      // render: (text, record) => record.name,
      render: (text, record) => record.setNumber,
      responsive: ['sm']
    }
  ];

  const columnsExerciseTmp = [
    {
      title: 'Exercise',
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.timestamp.createdAt}
          <br />
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: 'Fecha',
      dataIndex: ['timestamp.createdAt'],
      key: 'date',
      align: 'center',
      render: (text, record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY HH:mm'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  const onChangeSelect = async (e) => {
    const selectedAccount = accounts.find(account => account._id === e);
    setSelectedAccount(selectedAccount);
    await loadExerciseSessions(selectedAccount._id);
  };

  const onChangeSelectExercise = async (e) => {
    const sessions = exerciseSessions.filter(exerciseSession => exerciseSession.exercise === e);
    const exercise = centerExercises.find(exercise => exercise._id === e);
    const columns = [...columnsExerciseTmp];

    let setNumber = sessions[0].setNumber;

    sessions.forEach((session) => {
      if (session.setNumber < setNumber) {
        setNumber = session.setNumber;
      }
    });

    if (exercise.sensors === 'average') {
      for (let i = 1; i <= setNumber; i++) {
        columns.push({
          title: 'Set ' + i,
          dataIndex: ['set' + i],
          key: 'set' + i,
          align: 'center',
          render: (text, record) => {
            console.log('record', i - 1, record);
            let minArrow = '';
            let maxArrow = '';
            let avgArrow = '';
            if (record.previousSession) {
              if (record.previousSession.sets[i - 1].stats.datasets.mediaMin > record.sets[i - 1].stats.datasets.mediaMin) {
                minArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.mediaMin < record.sets[i - 1].stats.datasets.mediaMin) {
                minArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.mediaMax > record.sets[i - 1].stats.datasets.mediaMax) {
                maxArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.mediaMax < record.sets[i - 1].stats.datasets.mediaMax) {
                maxArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.mediaAvg > record.sets[i - 1].stats.datasets.mediaAvg) {
                avgArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.mediaAvg < record.sets[i - 1].stats.datasets.mediaAvg) {
                avgArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
            }
            return <div>
              <div><b>Time: </b> {record.sets[i - 1].time}{'\'\''}</div>
              <div><b>Percent: </b> {record.sets[i - 1].percent}{'%'}</div>
              <br />
              <div><b>Max: </b>{parseInt(record.sets[i - 1].stats.datasets.mediaMax).toFixed(1)} {maxArrow}</div>
              <div><b>Avg: </b>{parseInt(record.sets[i - 1].stats.datasets.mediaAvg).toFixed(1)} {avgArrow}</div>
              <div><b>Min: </b>{parseInt(record.sets[i - 1].stats.datasets.mediaMin).toFixed(1)} {minArrow}</div>
            </div>;
          },
          responsive: ['sm']
        });
      }
      setSetColumns(columns);
      sessions.forEach((session, i) => {
        if (i > 0) {
          session.previousSession = sessions[i - 1];
        }
      });
      setExerciseSessionResume(sessions.reverse());
      setSelectedExercise(exercise);
    } else if (exercise.sensors === 'left') {
      for (let i = 1; i <= setNumber; i++) {
        columns.push({
          title: 'Set ' + i,
          dataIndex: ['set' + i],
          key: 'set' + i,
          align: 'center',
          render: (text, record) => {
            console.log('record', i - 1, record);
            let minArrow = '';
            let maxArrow = '';
            let avgArrow = '';
            if (record.previousSession) {
              if (record.previousSession.sets[i - 1].stats.datasets.leftMin > record.sets[i - 1].stats.datasets.leftMin) {
                minArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftMin < record.sets[i - 1].stats.datasets.leftMin) {
                minArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.leftMax > record.sets[i - 1].stats.datasets.leftMax) {
                maxArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftMax < record.sets[i - 1].stats.datasets.leftMax) {
                maxArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.leftAvg > record.sets[i - 1].stats.datasets.leftAvg) {
                avgArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftAvg < record.sets[i - 1].stats.datasets.leftAvg) {
                avgArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
            }
            return <div>
              <div><b>Time: </b> {record.sets[i - 1].time}{'\'\''}</div>
              <div><b>Percent: </b> {record.sets[i - 1].percent}{'%'}</div>
              <br />
              <div><b>Max: </b>{parseInt(record.sets[i - 1].stats.datasets.leftMax).toFixed(1)} {maxArrow}</div>
              <div><b>Avg: </b>{parseInt(record.sets[i - 1].stats.datasets.leftAvg).toFixed(1)} {avgArrow}</div>
              <div><b>Min: </b>{parseInt(record.sets[i - 1].stats.datasets.leftMin).toFixed(1)} {minArrow}</div>
            </div>;
          },
          responsive: ['sm']
        });
      }
      setSetColumns(columns);
      sessions.forEach((session, i) => {
        if (i > 0) {
          session.previousSession = sessions[i - 1];
        }
      });
      setExerciseSessionResume(sessions.reverse());
      setSelectedExercise(exercise);
    } else if (exercise.sensors === 'right') {
      for (let i = 1; i <= setNumber; i++) {
        columns.push({
          title: 'Set ' + i,
          dataIndex: ['set' + i],
          key: 'set' + i,
          align: 'center',
          render: (text, record) => {
            console.log('record', i - 1, record);
            let minArrow = '';
            let maxArrow = '';
            let avgArrow = '';
            if (record.previousSession) {
              if (record.previousSession.sets[i - 1].stats.datasets.rightMin > record.sets[i - 1].stats.datasets.rightMin) {
                minArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightMin < record.sets[i - 1].stats.datasets.rightMin) {
                minArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.rightMax > record.sets[i - 1].stats.datasets.rightMax) {
                maxArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightMax < record.sets[i - 1].stats.datasets.rightMax) {
                maxArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.rightAvg > record.sets[i - 1].stats.datasets.rightAvg) {
                avgArrow = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightAvg < record.sets[i - 1].stats.datasets.rightAvg) {
                avgArrow = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
            }
            return <div>
              <div><b>Time: </b> {record.sets[i - 1].time}{'\'\''}</div>
              <div><b>Percent: </b> {record.sets[i - 1].percent}{'%'}</div>
              <br />
              <div><b>Max: </b>{parseInt(record.sets[i - 1].stats.datasets.rightMax).toFixed(1)} {maxArrow}</div>
              <div><b>Avg: </b>{parseInt(record.sets[i - 1].stats.datasets.rightAvg).toFixed(1)} {avgArrow}</div>
              <div><b>Min: </b>{parseInt(record.sets[i - 1].stats.datasets.rightMin).toFixed(1)} {minArrow}</div>
            </div>;
          },
          responsive: ['sm']
        });
      }
      setSetColumns(columns);
      sessions.forEach((session, i) => {
        if (i > 0) {
          session.previousSession = sessions[i - 1];
        }
      });
      setExerciseSessionResume(sessions.reverse());
      setSelectedExercise(exercise);
    } else if (exercise.sensors === 'both') {
      for (let i = 1; i <= setNumber; i++) {
        columns.push({
          title: 'Set ' + i,
          dataIndex: ['set' + i],
          key: 'set' + i,
          align: 'center',
          render: (text, record) => {
            // console.log('record', i - 1, record.sets[i - 1]);
            // console.log('previousSession', record.previousSession ? record.previousSession.sets[i - 1] : null);
            let minArrowL = '';
            let maxArrowL = '';
            let avgArrowL = '';
            let minArrowR = '';
            let maxArrowR = '';
            let avgArrowR = '';
            if (record.previousSession) {
              // console.log('previousSession', record.previousSession.sets[i - 1].stats.datasets);
              // console.log('record', record.sets[i - 1].stats.datasets);
              if (record.previousSession.sets[i - 1].stats.datasets.leftMin > record.sets[i - 1].stats.datasets.leftMin) {
                minArrowL = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftMin < record.sets[i - 1].stats.datasets.leftMin) {
                minArrowL = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.leftMax > record.sets[i - 1].stats.datasets.leftMax) {
                maxArrowL = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftMax < record.sets[i - 1].stats.datasets.leftMax) {
                maxArrowL = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.leftAvg > record.sets[i - 1].stats.datasets.leftAvg) {
                avgArrowL = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.leftAvg < record.sets[i - 1].stats.datasets.leftAvg) {
                avgArrowL = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.rightMin > record.sets[i - 1].stats.datasets.rightMin) {
                minArrowR = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightMin < record.sets[i - 1].stats.datasets.rightMin) {
                minArrowR = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.rightMax > record.sets[i - 1].stats.datasets.rightMax) {
                maxArrowR = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightMax < record.sets[i - 1].stats.datasets.rightMax) {
                maxArrowR = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
              if (record.previousSession.sets[i - 1].stats.datasets.rightAvg > record.sets[i - 1].stats.datasets.rightAvg) {
                avgArrowR = <ArrowDownOutlined style={{ color: 'red' }}/>;
              } else if (record.previousSession.sets[i - 1].stats.datasets.rightAvg < record.sets[i - 1].stats.datasets.rightAvg) {
                avgArrowR = <ArrowUpOutlined style={{ color: 'green' }}/>;
              }
            }
            return <div>
              <div><b>Time: </b> {record.sets[i - 1].time}{'\'\''}</div>
              <div><b>Percent: </b> {record.sets[i - 1].percent}{'%'}</div>
              <br />
              <div><b>Max Left: </b>{parseInt(record.sets[i - 1].stats.datasets.leftMax).toFixed(1)} {maxArrowL}</div>
              <div><b>Max Right: </b>{parseInt(record.sets[i - 1].stats.datasets.rightMax).toFixed(1)} {maxArrowR}</div>
              <br />
              <div><b>Avg Left: </b>{parseInt(record.sets[i - 1].stats.datasets.leftAvg).toFixed(1)} {avgArrowL}</div>
              <div><b>Avg Right: </b>{parseInt(record.sets[i - 1].stats.datasets.rightAvg).toFixed(1)} {avgArrowR}</div>
              <br />
              <div><b>Min Left: </b>{parseInt(record.sets[i - 1].stats.datasets.leftMin).toFixed(1)} {minArrowL}</div>
              <div><b>Min Right: </b>{parseInt(record.sets[i - 1].stats.datasets.rightMin).toFixed(1)} {minArrowR}</div>
            </div>;
          }
        });
      }
      setSetColumns(columns);
      sessions.forEach((session, i) => {
        if (i > 0) {
          session.previousSession = sessions[i - 1];
        }
      });
      setExerciseSessionResume(sessions.reverse());
      setSelectedExercise(exercise);
    }
  };

  const downloadExcel = async (e) => {
    const sessions = exerciseSessions.filter(exerciseSession => exerciseSession.exercise === e);
    const exercise = centerExercises.find(exercise => exercise._id === e);

    let setNumber = sessions[0].setNumber;

    sessions.forEach((session) => {
      if (session.setNumber < setNumber) {
        setNumber = session.setNumber;
      }
    });

    const accountData = [];
    accountData.push({
      v: 'Name: ',
      t: 's',
      s: {
        font: { sz: 12, bold: true },
        alignment: { horizontal: 'center' }
      }
    });

    accountData.push({
      v: selectedAccount.name + ' ' + selectedAccount.surname,
      t: 's',
      s: {
        font: { sz: 12, bold: false },
        alignment: { horizontal: 'center' }
      }
    });

    const exerciseData = [];
    exerciseData.push({
      v: 'Exercise: ',
      t: 's',
      s: {
        font: { sz: 12, bold: true },
        alignment: { horizontal: 'center' }
      }
    });

    exerciseData.push({
      v: exercise.name,
      t: 's',
      s: {
        font: { sz: 12, bold: false },
        alignment: { horizontal: 'center' }
      }
    });

    const aoaHeader = [];
    aoaHeader.push({
      v: 'Date',
      t: 's',
      s: {
        font: { sz: 12, bold: true },
        alignment: { horizontal: 'center' },
        border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
        fill: { fgColor: { rgb: 'FFC0C0C0' } }
      }
    });
    for (let i = 1; i <= setNumber; i++) {
      aoaHeader.push({
        v: 'Set ' + i,
        t: 's',
        s: {
          font: { sz: 12, bold: true },
          alignment: { horizontal: 'center' },
          border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
          fill: { fgColor: { rgb: 'FFC0C0C0' } }
        }
      });
    }

    if (exercise.sensors === 'average') {
      const sessionsReverse = sessions.reverse();
      const sessionsMapped = sessionsReverse.map((session) => {
        const sets = [];
        for (let i = 1; i <= setNumber; i++) {
          const set = session.sets[i - 1];
          sets.push({
            set: i,
            time: set.time,
            percent: set.percent,
            max: set.stats.datasets.mediaMax,
            avg: set.stats.datasets.mediaAvg,
            min: set.stats.datasets.mediaMin
          });
        }

        const row = [];
        row.push({ v: moment(session.timestamp.createdAt).format('DD/MM/YYYY') + '\n' + moment(session.timestamp.createdAt).format('HH:mm'), t: 's' });
        sets.forEach((set, i) => {
          row.push({ v: 'Time: ' + set.time + '\nPercent: ' + set.percent + '\n\nMax: ' + parseInt(set.max).toFixed(1) + '\nAvg: ' + parseInt(set.avg).toFixed(1) + '\nMin: ' + parseInt(set.min).toFixed(1), t: 's' });
        });
        return row;
      });

      sessionsMapped.unshift(aoaHeader);
      sessionsMapped.unshift([]);
      sessionsMapped.unshift(exerciseData);
      sessionsMapped.unshift(accountData);
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(sessionsMapped);
      // set all ws column widths to 20
      const wscols = [];
      for (let i = 0; i < 1 + setNumber; i++) {
        wscols.push({ wch: 20 });
      }
      ws['!cols'] = wscols;
      xlsx.utils.book_append_sheet(wb, ws, 'Exercise');
      xlsx.writeFile(wb, exercise.name + '-' + selectedAccount.surname + '.xlsx');
    } else if (exercise.sensors === 'left') {
      const sessionsReverse = sessions.reverse();
      const sessionsMapped = sessionsReverse.map((session) => {
        const sets = [];
        for (let i = 1; i <= setNumber; i++) {
          const set = session.sets[i - 1];
          sets.push({
            set: i,
            time: set.time,
            percent: set.percent,
            max: set.stats.datasets.leftMax,
            avg: set.stats.datasets.leftAvg,
            min: set.stats.datasets.leftMin
          });
        }

        const row = [];
        row.push({ v: moment(session.timestamp.createdAt).format('DD/MM/YYYY') + '\n' + moment(session.timestamp.createdAt).format('HH:mm'), t: 's' });
        sets.forEach((set, i) => {
          row.push({ v: 'Time: ' + set.time + '\nPercent: ' + set.percent + '\n\nMax: ' + parseInt(set.max).toFixed(1) + '\nAvg: ' + parseInt(set.avg).toFixed(1) + '\nMin: ' + parseInt(set.min).toFixed(1), t: 's' });
        });
        return row;
      });

      sessionsMapped.unshift(aoaHeader);
      sessionsMapped.unshift([]);
      sessionsMapped.unshift(exerciseData);
      sessionsMapped.unshift(accountData);
      console.log('sessionsMapped', sessionsMapped);
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(sessionsMapped);
      // set all ws column widths to 20
      const wscols = [];
      for (let i = 0; i < 1 + setNumber; i++) {
        wscols.push({ wch: 20 });
      }
      ws['!cols'] = wscols;
      xlsx.utils.book_append_sheet(wb, ws, 'Exercise');
      xlsx.writeFile(wb, exercise.name + '-' + selectedAccount.surname + '.xlsx');
    } else if (exercise.sensors === 'right') {
      const sessionsReverse = sessions.reverse();
      const sessionsMapped = sessionsReverse.map((session) => {
        const sets = [];
        for (let i = 1; i <= setNumber; i++) {
          const set = session.sets[i - 1];
          sets.push({
            set: i,
            time: set.time,
            percent: set.percent,
            max: set.stats.datasets.rightMax,
            avg: set.stats.datasets.rightAvg,
            min: set.stats.datasets.rightMin
          });
        }

        const row = [];
        row.push({ v: moment(session.timestamp.createdAt).format('DD/MM/YYYY') + '\n' + moment(session.timestamp.createdAt).format('HH:mm'), t: 's' });
        sets.forEach((set, i) => {
          row.push({ v: 'Time: ' + set.time + '\nPercent: ' + set.percent + '\n\nMax: ' + parseInt(set.max).toFixed(1) + '\nAvg: ' + parseInt(set.avg).toFixed(1) + '\nMin: ' + parseInt(set.min).toFixed(1), t: 's' });
        });
        return row;
      });

      sessionsMapped.unshift(aoaHeader);
      sessionsMapped.unshift([]);
      sessionsMapped.unshift(exerciseData);
      sessionsMapped.unshift(accountData);
      console.log('sessionsMapped', sessionsMapped);
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(sessionsMapped);
      // set all ws column widths to 20
      const wscols = [];
      for (let i = 0; i < 1 + setNumber; i++) {
        wscols.push({ wch: 20 });
      }
      ws['!cols'] = wscols;
      xlsx.utils.book_append_sheet(wb, ws, 'Exercise');
      xlsx.writeFile(wb, exercise.name + '-' + selectedAccount.surname + '.xlsx');
    } else if (exercise.sensors === 'both') {
      const sessionsReverse = sessions.reverse();
      const sessionsMapped = sessionsReverse.map((session) => {
        const sets = [];
        for (let i = 1; i <= setNumber; i++) {
          const set = session.sets[i - 1];
          sets.push({
            set: i,
            time: set.time,
            percent: set.percent,
            maxL: set.stats.datasets.leftMax,
            avgL: set.stats.datasets.leftAvg,
            minL: set.stats.datasets.leftMin,
            maxR: set.stats.datasets.rightMax,
            avgR: set.stats.datasets.rightAvg,
            minR: set.stats.datasets.rightMin
          });
        }

        const row = [];
        row.push({ v: moment(session.timestamp.createdAt).format('DD/MM/YYYY') + '\n' + moment(session.timestamp.createdAt).format('HH:mm'), t: 's' });
        sets.forEach((set, i) => {
          row.push({ v: 'Time: ' + set.time + '\nPercent: ' + set.percent + '\n\nMax Left: ' + parseInt(set.maxL).toFixed(1) + '\nMax Right: ' + parseInt(set.maxR).toFixed(1) + '\n\nAvg Left: ' + parseInt(set.avgL).toFixed(1) + '\nAvg Right: ' + parseInt(set.avgR).toFixed(1) + '\n\nMin Left: ' + parseInt(set.minL).toFixed(1) + '\nMin Right: ' + parseInt(set.minR).toFixed(1), t: 's' });
        });
        return row;
      });

      sessionsMapped.unshift(aoaHeader);
      sessionsMapped.unshift([]);
      sessionsMapped.unshift(exerciseData);
      sessionsMapped.unshift(accountData);
      console.log('sessionsMapped', sessionsMapped);
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(sessionsMapped);
      // set all ws column widths to 20
      const wscols = [];
      for (let i = 0; i < 1 + setNumber; i++) {
        wscols.push({ wch: 20 });
      }
      ws['!cols'] = wscols;
      xlsx.utils.book_append_sheet(wb, ws, 'Exercise');
      xlsx.writeFile(wb, exercise.name + '-' + selectedAccount.surname + '.xlsx');
    }
  };

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record._id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
        <h3>{capitalizeAll(t('Users'))}</h3>
        <div>
          <Select
            showSearch
            id='account'
            style={{ width: 200 }}
            placeholder={capitalize(t('Select a user'))}
            optionFilterProp='children'
            onChange={onChangeSelect}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {accounts.map((account, i) => (
              <Option key={i} value={account._id}>{account.name} {account.surname}</Option>
            ))}
          </Select>
          {accountExercises?.exercises?.length && <>

            <h3 style={{ marginTop: 12 }}>{capitalizeAll(t('Exercise'))}</h3>
            <Select
              id='exercise'
              showSearch
              style={{ width: 200 }}
              placeholder={capitalize(t('Select a exercise'))}
              optionFilterProp='children'
              value = {selectedExercise?.name || '' }
              onChange={onChangeSelectExercise}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {accountExercises.exercises.map((ex, i) => {
                const exercise = centerExercises.find((exercise) => exercise._id === ex);
                return <Option key={i} value={exercise._id}>{exercise.name}</Option>;
              })}
            </Select>
          </>}

        </div>
        {selectedExercise && exerciseSessionResume.length && <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <button onClick={() => downloadExcel(selectedExercise._id)} style={{ height: 30, marginRight: 12, display: 'flex', justifyContent: 'space-around', alignItems: 'center', paddingLeft: 8, paddingRight: 8 }}><DownloadOutlined style={{ fontSize: '24px', color: '#000', marginRight: 4 }} /> <b>DOWNLOAD EXCEL</b> </button>
            <button onClick={handlePrint} style={{ width: 30, height: 30 }}><PrinterOutlined style={{ fontSize: '24px', color: '#000' }} /></button>
          </div>
          <div ref={componentRef}>
            <h2>{capitalizeAll(t('exercise'))}: {selectedExercise.name}</h2>
            <div className='exercise-info-stats'>
              <div>
                <b>{capitalizeAll(t('user'))}</b>: {selectedAccount.name} {selectedAccount.surname}<br/>
                <b>{capitalize(t('chair'))}</b>: {selectedExercise.chair}<br/>
                <b>{capitalize(t('backChair'))}</b>: {selectedExercise.back}<br/>
                <b>{capitalize(t('sensors'))}</b>: {selectedExercise.sensors}<br/>
                <b>{capitalize(t('mode'))}</b>: {selectedExercise.mode}<br/>
              </div>
              <div>
                <b>{capitalize(t('apost'))}</b>: {selectedExercise.ap}<br/>
                <b>{capitalize(t('lm'))}</b>: {selectedExercise.lm}<br/>
                <b>{capitalize(t('lg'))}</b>: {selectedExercise.lg}<br/>
                <b>{capitalize(t('handleVert'))}</b>: {selectedExercise.handleVert}<br/>
                <b>{capitalize(t('handleHoriz'))}</b>: {selectedExercise.handleHoriz}<br/>
              </div>
            </div>
            <Table columns={setColumns} dataSource={exerciseSessionResume} onChange={onChange}
              rowKey={(record) => record._id}
              rowClassName={'row-pointer'}
              pagination={false}
            />
          </div>
        </div>}
        {/* <Table columns={columnsAccounts} dataSource={exerciseSessions} onChange={onChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {}, // click row
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {} // mouse leave row
            };
          }}
          expandedRowKeys={expandedRowKeys}
          onExpand={onTableRowExpand}
          rowKey={(record) => record._id}
          rowClassName={'row-pointer'}
          expandable={{
          // eslint-disable-next-line react/display-name
            expandedRowRender: (record) => {
              return <React.Fragment>
                <div key={record._id}>
                  <Set
                    exerciseSession={record}
                    t={t}
                    accounts={accounts}
                    centerExercises={centerExercises}/>
                </div>
              </React.Fragment>;
            },
            // rowExpandable: record => record._id !== 'Not Expandable',
            expandRowByClick: true
          }}
        /> */}
      </div>
    </>
  );
};

export default Charts;
