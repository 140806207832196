import React, { useEffect, useState } from 'react';
// import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getAccountByQuery } from '../../../services/user.service';
import { Input } from 'antd';
import Spinner from '../../../components/navigation/Spinner';
import Form from 'antd/es/form/Form';
import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { getProgramSessionByQuery } from '../../../services/programsession.service';
import moment from 'moment';
import bstLogo from '../../../assets/images/logoBST.png';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
import { getCenterById } from '../../../services/center.service';

moment.locale('es');
// import { getIdToken } from 'firebase/auth';

const Device = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [nifForm] = Form.useForm();
  const [value, setValue] = useState(new Date());
  const [center, setCenter] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(new Date());
      // loadAccounts(params.center);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = (e) => {
    switch (e.detail) {
    case 1:
      break;
    case 2:
      if (document.fullscreenEnabled) {
        document.documentElement.requestFullscreen();
      }
      break;
    case 3:
      break;
    default:
    }
  };

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    loadAccounts(params.center);
    // }
  }, []);

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId });
    if (status === 200) {
      data.data.forEach(account => {
        account.key = account._id;
      });
      await loadCenter(centerId);
      await loadProgramSessions(centerId, data.data);
      setLoading(false);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId);
    if (status === 200) {
      i18n.changeLanguage(data.data.language);
      localStorage.setItem('language', data.data.language);
      setCenter(data.data);
    }
  };

  const loadProgramSessions = async (centerId, pAccounts) => {
    const { status, data } = await getProgramSessionByQuery({ center: centerId, isDeleted: false, isActive: true });
    if (status === 200) {
      mergeAccountsWithSession(data.data, pAccounts);
    } else {
      setAccounts([]);
    }
  };

  const mergeAccountsWithSession = async (pSessions, pAccounts) => {
    const finalAccounts = [];
    pSessions.forEach((session) => {
      pAccounts.forEach((account) => {
        if (account._id === session.account) {
          finalAccounts.push(account);
        }
      });
    });
    const noDuplicate = new Set(finalAccounts);
    const noDuplicateArr = [...noDuplicate];
    setAccounts(noDuplicateArr);
  };

  const searchAccount = (data) => {
    const accountFound = accounts.filter(account => {
      for (const letter in data) {
        console.log('letter', letter);
        if (account.nif[letter] !== data[letter]) {
          return null;
        }
      }
      return account;
      // if (account.nif.includes(data)) return account;
      // return null;
    });
    if (data.length <= 2) {
      loadAccounts(params.center);
    }
    console.table(accountFound);
    if (accountFound.length === 1 && data.length > 2) {
      navigate(accountFound[0]._id);
    }
  };

  const addNumber = (data) => {
    let inputNif = '';
    if (nifForm.getFieldValue('nif')) inputNif = nifForm.getFieldValue('nif');
    nifForm.setFieldsValue({
      nif: inputNif + data.target.innerText
    });
    searchAccount(nifForm.getFieldValue('nif'));
  };

  const deleteNumber = () => {
    const inputNif = nifForm.getFieldValue('nif');
    if (inputNif) {
      nifForm.setFieldsValue({
        nif: inputNif.substring(0, inputNif.length - 1)
      });
    }
  };

  const resetNumber = () => {
    nifForm.setFieldsValue({
      nif: ''
    });
  };

  if (loading) { return <Spinner dark/>; }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ height: '100vh', justifyContent: 'space-betweem', alignItems: 'center', display: 'flex', flexDirection: 'row', wrap: 'nowrap' }}>
          <div className='buttonsPage' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row', fontSize: 24, width: '100%' }}>
            <div className={`logo-div${center.theme}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingTop: 55, paddingBottom: 55 }}>
              <div></div>
              <div style={{ width: '100%' }}>
                <img style={{ maxHeight: 380, maxWidth: 350 }} src={center.logo} alt={'logo'}/>
              </div>
              <div>
                {/* <div>{moment(value).format('dddd DD')} de {moment(value).format('MMMM')}</div> */}
                <div style={{ fontSize: 64 }}>{moment(value).format('HH:mm:ss')}</div>
              </div>

            </div>

            <Form
              form={nifForm}
              layout="vertical"
              autoComplete="off"
              className={`loginForm${center.theme}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
            >
              <Form.Item
                name="nif"
                value={''}
                // className='nifForm'
                style={{ }}
              >
                {/* {nifForm.getFieldValue('nif')} */}
                <Input className={`nifForm${center.theme}`} onChange={searchAccount} style={{ textAlign: 'center' }}/>
              </Form.Item>
              <div className='divButtons'>
                <div className='rowButtons'>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>1</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>2</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>3</button>
                </div>
                <div className='rowButtons'>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>4</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>5</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>6</button>
                </div>
                <div className='rowButtons'>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>7</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>8</button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>9</button>
                </div>
                <div className='rowButtons'>
                  <button className={`numberButton${center.theme}`} onClick={deleteNumber} style={{ }}><LeftOutlined style={{ fontSize: 24 }} /></button>
                  <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>0</button>
                  <button className={`numberButton${center.theme}`} onClick={resetNumber} style={{ }}><DeleteOutlined style={{ fontSize: 24 }} /></button>
                </div>
              </div>

            </Form>

          </div>
        </div>
      </div>
    </>
  );
};
export default Device;
