import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgramByQueryWithExercises } from '../../../services/program.service';
import Spinner from '../../../components/navigation/Spinner';
import { Button, Select, Collapse } from 'antd';
import { format } from 'date-fns';
import { fadeIn, bounceInUp, slideInUp, fadeOut, zoomIn, slideOutUp, merge } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { getAccountById, patchAccount } from '../../../services/user.service';
import { createWeight, getWeightByQuery, patchWeight } from '../../../services/weight.service';
import WeightControl from '../../../components/modals/WeightControl';
import { LeftOutlined, LogoutOutlined } from '@ant-design/icons';
import StartProgramButton from '../../../components/buttons/StartProgram';
import { useTranslation } from 'react-i18next';
import FullScreenMessageModal from '../../../components/modals/FullScreenMessage';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { getDeviceByQuery } from '../../../services/device.service';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import ProgressControl from '../../../components/modals/ProgressControl';
import {
  getProgramSessionById,
  getProgramSessionByQuery,
  patchProgramSession
} from '../../../services/programsession.service';
import moment from 'moment';
import 'moment/locale/es';
import { getCenterById } from '../../../services/center.service';
import axios from 'axios';
import { io } from 'socket.io-client';
import { createExerciseSession } from '../../../services/exercisesession.service';
const fadeUp = merge(fadeIn, slideInUp);
let socket;

const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },
  fadeOut: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(fadeOut, 'fadeOut')
  },
  bounceInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceInUp, 'bounceInUp')
  },
  slideInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideOutUp: {
    animation: 'x 4s',
    animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
  },
  zoomIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn')
  },
  fadeUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeUp, 'fadeUp')
  }
};

const Set = (props) => {
  const [time, setTime] = useState(props.set.time ? props.set.time : '10');
  const [percent, setPercent] = useState(props.set.percent ? props.set.percent : '60');

  useEffect(() => {
    props.handleModifySet(props.set.set, time.toString(), percent);
  }, [time, percent]);

  return (
    <div className={'set'}>
      <div>
        #{props.set.set}
      </div>
      <div className="setConfig">
        <div className={'time'}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: props.isMobile ? 24 : 32 }}>
            <button className={`basicButton${props.center.theme}`} style={{ fontSize: props.isMobile ? 24 : 32, height: '100%', margin: 0 }} onClick={() => {
              if (parseInt(time) > 1) {
                setTime(parseInt(time) - 1);
              }
            }}>-</button>
            <div style={{ marginRight: props.isMobile ? 5 : 15, marginLeft: props.isMobile ? 5 : 15, width: '100%', textAlign: 'center' }}>{time}{'"'}</div>
            <button className={`basicButton${props.center.theme}`} style={{ fontSize: props.isMobile ? 24 : 32, height: '100%', margin: 0 }} onClick={() => {
              setTime(parseInt(time) + 1);
            }}>+</button>
          </div>
        </div>
        <div className={'setPercent'}>
          <select className={'percentSelect'} defaultValue={percent} onChange={(e) => setPercent(parseInt(e.target.value))} style={{ fontSize: props.isMobile ? 24 : 32, width: props.isMobile && 120, height: props.isMobile && 60 }}>
            <option value={'10'}>10%</option>
            <option value={'20'}>20%</option>
            <option value={'30'}>30%</option>
            <option value={'40'}>40%</option>
            <option value={'50'}>50%</option>
            <option value={'60'}>60%</option>
            <option value={'70'}>70%</option>
            <option value={'80'}>80%</option>
            <option value={'90'}>90%</option>
            <option value={'100'}>100%</option>
          </select>
        </div>
      </div>

    </div>
  );
};

const ClientPrograms = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const [programsPosition, setProgramPosition] = useState('center');
  const [programsHeight, setProgramHeight] = useState('80vh');
  const [openProgram, setOpenProgram] = useState(null);
  const [account, setAccount] = useState(null);
  const [weight, setWeight] = useState(null);
  const [showOpenProgram, setShowOpenProgram] = useState(false);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [programsAnimation, setProgramAnimation] = useState(styles.fadeIn);
  const [programsTimeout, setProgramsTimeout] = useState();
  const [devices, setDevices] = useState([]);
  const [weightHistory, setWeightHistory] = useState([]);
  const [accountWeightUpdated, setAccountWeightUpdated] = useState(false);
  const [weightModalVisible, setWeightModalVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messageModal, setMessageModal] = useState('READY');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedWeightNumber, setSelectedWeightNumber] = useState(80);
  const [selectedWeightDecimal, setSelectedWeightDecimal] = useState(5);
  const [progressControlModalVisible, setProgressControlModalVisible] = useState(false);
  const [programSessions, setProgramSessions] = useState([]);
  const [openSession, setOpenSession] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [exerciseResult, setExerciseResult] = useState(null);
  const [finishedExercise, setFinishedExercise] = useState(false);
  const [inter, setInter] = useState(true);
  const [finishedProgram, setFinishedProgram] = useState(false);
  const [setsNumber, setSetsNumber] = useState(1);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedOption, setSelectedOption] = useState('0');
  const [center, setCenter] = useState(null);
  const [actualProgramSession, setActualProgramSession] = useState(null);
  const [tare, setTare] = useState('');
  const [sets, setSets] = useState([{
    set: setsNumber,
    time: '10',
    percent: '60'
  }]);
  const { Panel } = Collapse;
  const [exerciseInProgress, setExerciseInProgress] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange () {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  console.log('isMobile', isMobile);

  useEffect(() => {
    if (center) {
      console.log('CENTER SOCKET', center);
      if (!socket) {
        socket = io(center.machine + ':3030', { transports: ['websocket'] });
      }

      socket.on('finishExercise', (data) => {
        if (data.sets && data.sets[0] && data.sets[0].set) {
          setSelectedSet(data.sets[0].set);
          setExerciseResult(data);
        } else {
          setSelectedSet(null);
          setExerciseResult(null);
        }
        setFinishedExercise(true);
        setTare('');
        // handleFinishExercise();
      });
    }
  }, [center]);

  useEffect(() => {
    if (selectedSet) {
      setSelectedOption('0');
    }
  }, [selectedSet]);

  useEffect(() => {
    if (finishedExercise && inter) {
      setExerciseInProgress(false);
      console.log('exs', selectedProgram.exercises);
      console.log('ex', selectedExercise);
      if (selectedProgram && exerciseResult && selectedExercise) {
        handleSaveExerciseResults(exerciseResult);
      }
      const exerciseIndex = selectedProgram.exercises.findIndex(exercise => exercise._id === selectedExercise._id);
      if (exerciseIndex < selectedProgram.exercises.length - 1) {
        setSelectedExercise(selectedProgram.exercises[exerciseIndex + 1]);
        if (selectedProgram.exercises[exerciseIndex + 1].setNumber && selectedProgram.exercises[exerciseIndex + 1].setNumber > 0) {
          setSetsNumber(selectedProgram.exercises[exerciseIndex + 1].setNumber);
          const sets = [];
          for (let i = 0; i < selectedProgram.exercises[exerciseIndex + 1].setNumber; i++) {
            sets.push({
              set: selectedProgram.exercises[exerciseIndex + 1].sets[i].key + 1,
              time: selectedProgram.exercises[exerciseIndex + 1].sets[i].time.toString(),
              percent: selectedProgram.exercises[exerciseIndex + 1].sets[i].percent.toString()
            });
          }
          setSets(sets);
        }
        setInter(false);
      } else {
        console.log('ENTRAAAAAAA', exerciseIndex, selectedProgram.exercises.length - 1);
        setFinishedProgram(true);
      }
      handleResetSets();
    }
  }, [exerciseResult, selectedExercise, selectedProgram, finishedExercise, actualProgramSession]);

  const handleSaveExerciseResults = async (exerciseResult) => {
    let done;
    let maxStrengths;

    const maxStrengthSet = exerciseResult.sets.find(set => parseInt(set.percent) === 100);
    if (maxStrengthSet) {
      const account = await getAccountById(params.account);
      if (account.status === 200) {
        if (account.data.data.maxStrengths) {
          maxStrengths = [...account.data.data.maxStrengths];
        } else {
          maxStrengths = [];
        }
        const maxStrengthIndex = maxStrengths.findIndex(maxStrength => maxStrength.exercise === selectedExercise._id);
        if (maxStrengthIndex !== -1) {
          maxStrengths[maxStrengthIndex].strength = maxStrengthSet.stats.datasets;
        } else {
          maxStrengths.push({
            exercise: selectedExercise._id,
            strength: maxStrengthSet.stats.datasets
          });
        }
        await patchAccount(params.account, { maxStrengths });
      }
    }

    const { data, status } = await getProgramSessionById(actualProgramSession._id);
    if (status === 200) {
      if (data.data.done) {
        done = [...data.data.done];
      } else {
        done = [];
      }
      done.push(selectedExercise._id);
      await patchProgramSession(actualProgramSession._id, {
        done
      });
      const selectedProgramUpdate = selectedProgram;
      selectedProgramUpdate.done = done;
      setSelectedProgram(selectedProgramUpdate);
    }

    const payload = {
      account: account._id,
      exercise: selectedExercise._id,
      sets: exerciseResult.sets,
      setNumber: exerciseResult.sets.length,
      programSession: selectedProgram._id
    };

    await createExerciseSession(payload);
  };

  const handleCreateProgramSession = (programSession) => {
    console.log('programSession', programSession);
    setActualProgramSession(programSession.data.data);
  };

  const handleAddSet = () => {
    setSets([...sets, {
      set: setsNumber + 1,
      time: '10',
      percent: '60'
    }]);
    setSetsNumber(setsNumber + 1);
  };

  const handleRemoveSet = () => {
    if (setsNumber > 1) {
      setSets([...sets.slice(0, setsNumber - 1)]);
      setSetsNumber(setsNumber - 1);
    }
  };

  const handleModifySet = (setsNumber, time, percent) => {
    const newSets = [...sets];
    newSets[setsNumber - 1].time = time;
    newSets[setsNumber - 1].percent = percent;
    setSets(newSets);
  };

  const handleResetSets = () => {
    setSets([{
      set: 1,
      time: '10',
      percent: '60'
    }]);
    setSetsNumber(1);
  };

  const handleStartExercise = async () => {
    // axios.post(process.env.REACT_MACHINE_URL + '/start', {
    // console.log('LLLLLLLLLLLL', process.env.REACT_APP_MACHINE_URL);
    // console.log('FFFFFF', process.env.REACT_APP_URL);
    // console.log('CCCCCC', process.env.REACT_APP_API_URL);
    axios.post(center.machine + ':3030/start', {
      sets: sets,
      sensors: selectedExercise.sensors,
      mode: selectedExercise.mode
    })
      .then(function (response) {
        console.log(response);
        setExerciseInProgress(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleStopExercise = async () => {
    axios.post(center.machine + ':3030/stop')
      .then(function (response) {
        navigate(-1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleNextExercise = async () => {
    axios.post(center.machine + ':3030/clearscreen')
      .then(function (response) {
        console.log('NEXT EXERCISE');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFinishExercise = async () => {
    setFinishedExercise(true);
    setExerciseInProgress(false);
    console.log('exs', selectedProgram.exercises);
    console.log('ex', selectedExercise);
    const exerciseIndex = selectedProgram.exercises.findIndex(exercise => exercise._id === selectedExercise._id);
    if (exerciseIndex < selectedProgram.exercises.length - 1) {
      setSelectedExercise(selectedProgram.exercises[exerciseIndex + 1]);
    }
    handleResetSets();
  };

  console.log('-----setnumber', setsNumber, 'sets', sets);

  const handleCloseProgressControlModal = () => {
    setProgressControlModalVisible(false);
  };

  const handleClick = (e) => {
    switch (e.detail) {
    case 1:
      break;
    case 2:
      if (document.fullscreenEnabled && !isMobile) {
        document.documentElement.requestFullscreen();
      }
      break;
    case 3:
      break;
    default:
    }
  };

  useEffect(() => {
    if (programsTimeout === 'up') {
      setProgramAnimation(styles.fadeOut);
      const timer = setTimeout(() => {
        setProgramPosition('flex-start');
        setProgramHeight('auto');
        setProgramAnimation(styles.fadeUp);
        setShowOpenProgram(true);
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [programsTimeout]);

  useEffect(() => {
    if (messageModalVisible) {
      setMessageModal(t('ready').toUpperCase());
      const time = 0;
      const timer = setInterval(() => {
        // setMessageModal(time);
        // time--;
        // if (time === -1) {
        clearInterval(timer);
        setMessageModalVisible(false);
        console.log('PROGRAM', selectedProgram);
        setSelectedExercise(selectedProgram.exercises[0]);
        if (selectedProgram.exercises[0].setNumber && selectedProgram.exercises[0].setNumber > 0) {
          setSetsNumber(selectedProgram.exercises[0].setNumber);
          const setTemp = selectedProgram.exercises[0].sets.map(set => {
            return {
              set: set.key + 1,
              time: set.time.toString(),
              percent: set.percent.toString()
            };
          });
          console.log('SETS', setTemp);
          setSets(setTemp);
        }
        setOpenSession(true);
        // navigate(`/training/${params.center}`);
        // }
      }, 1);
    }
  }, [messageModalVisible, selectedProgram]);

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    loadAccount(params.account);
    // }
  }, []);

  const loadAccount = async (accountId) => {
    const { status, data } = await getAccountById(accountId);
    if (status === 200) {
      setAccount(data.data);
      await loadDevices(data.data.center);
      await loadCenter(data.data.center);
      const programSessions = await loadProgramSessions(data.data.center);
      await loadAccountWeight(data.data._id);
      await loadPrograms(params.account, programSessions);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId);
    console.log('CENTER', data.data);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const loadAccountWeight = async (accountId, token) => {
    const { status, data } = await getWeightByQuery({ account: accountId }, token);
    if (status === 200) {
      if (data.data[data.data.length - 1]?.weight) {
        setWeight(data.data[data.data.length - 1]);
        setWeightHistory(data.data);
        // setAccountWeight(data.data[data.data.length - 1].weight);
        setSelectedWeightNumber(data.data[data.data.length - 1].weight.toString().split('.')[0]);
        setSelectedWeightDecimal(data.data[data.data.length - 1].weight.toString().split('.')[1][0]);
      } else {
        // setAccountWeight(60);
        setSelectedWeightNumber(60);
        setSelectedWeightDecimal(5);
      }
    }
  };

  const loadProgramSessions = async (centerId) => {
    const { status, data } = await getProgramSessionByQuery({ center: centerId });
    if (status === 200) {
      setProgramSessions(data.data);
      return data.data;
    }
  };

  const loadDevices = async (centerId) => {
    const { status, data } = await getDeviceByQuery({ center: centerId });
    if (status === 200) {
      setDevices(data.data);
    }
  };

  const loadPrograms = async (accountId, programSessions) => {
    const { status, data } = await getProgramByQueryWithExercises({ account: accountId });
    if (status === 200) {
      console.log('programSessions', programSessions);
      data.data.forEach(program => {
        const programSessionsFilter = programSessions.filter(programSession => programSession.program === program._id);
        console.log('t', programSessionsFilter);
        program.key = program._id;
        if (programSessionsFilter.length > 0 && programSessionsFilter[programSessionsFilter.length - 1].closed) {
          program.lastSession = moment(programSessionsFilter[programSessionsFilter.length - 1].closed).format('DD/MM/YYYY');
        } else if (!programSessionsFilter.length) {
          program.lastSession = capitalize(t('noSession'));
        } else {
          program.lastSession = capitalize(t('active'));
          setActualProgramSession(programSessionsFilter[programSessionsFilter.length - 1]);
        }
      });
      setPrograms(data.data);
      setLoading(false);
    }
  };

  const addStyle = url => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;

    document.head.appendChild(style);
  };

  const handleStartSession = () => {
    setMessageModalVisible(true);
    // navigate(`/training/${params.center}`);
  };

  const renderExercise = (exercise) => {
    const devicesWithExercise = [];
    devices.forEach(device => {
      device.exercises.forEach(ex => {
        if (ex === exercise._id) {
          devicesWithExercise.push(device);
        }
      });
    });
    return (
      <>
        <div className={`exerciseCard${center.theme}`}>
          {/* <div></div> */}
          <div style={{ }}>{exercise.name}</div>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', width: '100%' }}>{devicesWithExercise.map((dev, i) => { */}
          {/*  const ret = dev.identifier; */}
          {/*  if (i === devicesWithExercise.length - 1) { */}
          {/*    return ret; */}
          {/*  } else { */}
          {/*    return `${ret}, `; */}
          {/*  } */}
          {/* })}</div> */}
        </div>
      </>
    );
  };

  const renderProgram = (program) => {
    let bgColor;
    let color;
    if (openProgram === program) {
      bgColor = '#A33E3E';
      color = 'white';
    }
    return (
      <>
        <div className={`programButton${center.theme}`} style={{ background: bgColor, color: color }} onClick={() => {
          setProgramsTimeout('up');
          setSelectedProgram(program);
          setOpenProgram(program);
        }}>
          {/* <div className="programButton" style={{ background: bgColor }}> */}
          <div>
            {program.name.slice(0, 20) + (program.name.length > 20 ? '...' : '')}
          </div>
          <div style={{ fontSize: 12, color: 'black' }}>
            {program.lastSession}
          </div>
          {/* </div> */}
          {/* <Card size="small" style={{ width: 200, height: 100, margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24, background: bgColor, borderRadius: 10 }} hoverable> */}
          {/* </Card> */}
        </div>

      </>
    );
  };

  const renderExercises = (program) => {
    return (
      <>
        <div style={[{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }, styles.fadeIn]}>
          {program.exercises.map(exercise => exercise.isActive ? renderExercise(exercise) : null)}
        </div>
        <div style={[{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', margin: 15 }, styles.slideInUp]}>
          <StartProgramButton program={program} theme={center.theme} account={account} centerId={params.center} handleStartSession={handleStartSession} handleCreateProgramSession={handleCreateProgramSession} handleStopExercise={handleStopExercise}/>
        </div>
      </>
    );
  };

  if (loading) {
    return (<Spinner dark/>);
  }

  addStyle(process.env.PUBLIC_URL + `/assets/css/wheel-style${center.theme}.css`);

  const renderSetInfo = (sensors, setNumber, exerciseResult) => {
    if (setNumber) {
      console.log('exerciseResult', exerciseResult);
      console.log('setNumber', setNumber);
      const set = exerciseResult.sets.find(s => s.set === setNumber);
      console.log('set', set);
      let max, min, avg;
      switch (sensors) {
      case 'both':
        max = set.stats.datasets.leftMax + ' / ' + set.stats.datasets.rightMax;
        min = set.stats.datasets.leftMin + ' / ' + set.stats.datasets.rightMin;
        avg = set.stats.datasets.leftAvg + ' / ' + set.stats.datasets.rightAvg;
        break;
      case 'left':
        max = set.stats.datasets.leftMax;
        min = set.stats.datasets.leftMin;
        avg = set.stats.datasets.leftAvg;
        break;
      case 'right':
        max = set.stats.datasets.rightMax;
        min = set.stats.datasets.rightMin;
        avg = set.stats.datasets.rightAvg;
        break;
      case 'average':
        max = set.stats.datasets.mediaMax;
        min = set.stats.datasets.mediaMin;
        avg = set.stats.datasets.mediaAvg;
        break;
      default:
        break;
      }
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: 10, fontSize: isMobile ? 16 : 18 }}>
            <div style={{ display: 'flex', flexDirection: 'row', color: 'black', padding: 5, gap: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '1' ? 'bold' : 'normal' }} */}
                {/*  onClick={() => { */}
                {/*    setSelectedOption('1'); */}
                {/*    axios.post(center.machine + ':3030/exerciseInfo', { */}
                {/*      exercise: set, */}
                {/*      command: 'sector', */}
                {/*      sensors: sensors */}
                {/*    }); */}
                {/*  }} */}
                {/* >Mostrar gráfica del sector en pantalla</div> */}
                <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '2' ? 'bold' : 'normal' }}
                  onClick={() => {
                    setSelectedOption('2');
                    axios.post(center.machine + ':3030/exerciseInfo', {
                      exercise: set,
                      command: 'max',
                      sensors: sensors
                    });
                  }}
                >{capitalize(t('displayHighestPeak'))}</div>
                <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '3' ? 'bold' : 'normal' }}
                  onClick={() => {
                    setSelectedOption('3');
                    axios.post(center.machine + ':3030/exerciseInfo', {
                      exercise: set,
                      command: 'min',
                      sensors: sensors
                    });
                  }}
                >{capitalize(t('displayLowestPeak'))}</div>
                { sensors === 'both' && <>
                  <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '4' ? 'bold' : 'normal' }}
                    onClick={() => {
                      setSelectedOption('4');
                      axios.post(center.machine + ':3030/exerciseInfo', {
                        exercise: set,
                        command: 'left',
                        sensors: sensors
                      });
                    }}
                  >{capitalize(t('displayeLeftSensor'))}</div>
                  <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '5' ? 'bold' : 'normal' }}
                    onClick={() => {
                      setSelectedOption('5');
                      axios.post(center.machine + ':3030/exerciseInfo', {
                        exercise: set,
                        command: 'right',
                        sensors: sensors
                      });
                    }}
                  >{capitalize(t('displayeRightSensor'))}</div>
                  <div style={{ color: 'black', cursor: 'pointer', fontWeight: selectedOption === '6' ? 'bold' : 'normal' }}
                    onClick={() => {
                      setSelectedOption('6');
                      axios.post(center.machine + ':3030/exerciseInfo', {
                        exercise: set,
                        command: 'both',
                        sensors: sensors
                      });
                    }}
                  >{capitalize(t('displayBothSensors'))}</div>
                </>}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 25, textAlign: 'end' }}>
                <div style={{ color: 'black' }}>{capitalize(t('avgStrength'))}: <span style={{ fontWeight: 'bold' }}>{avg}</span></div>
                <div style={{ color: 'black' }}>{capitalize(t('maxStrength'))}: <span style={{ fontWeight: 'bold' }}>{max}</span></div>
                <div style={{ color: 'black' }}>{capitalize(t('minStrength'))}: <span style={{ fontWeight: 'bold' }}>{min}</span></div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  if (finishedExercise) {
    console.log('finishedProgram', finishedProgram);
    return (<>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ minHeight: '100vh' }}>
          <div className="exercise-info" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '2rem', width: '100%', marginTop: 10 }}>
            Ejercicio completado
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {/* <div style={{ fontSize: '1.3rem', width: '100%', textAlign: 'center', cursor: 'pointer', fontWeight: selectedOption === '0' ? 'bold' : 'normal' }} */}
            {/*  onClick={() => { */}
            {/*    setSelectedOption('0'); */}
            {/*    axios.post(center.machine + ':3030/exerciseInfo', { */}
            {/*      exercise: exerciseResult.sets, */}
            {/*      command: 'all', */}
            {/*      sensors: exerciseResult.sensors */}
            {/*    }); */}
            {/*  }} */}
            {/* >Mostrar toda la gráfica</div> */}
            {exerciseResult && (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                {/* <Select defaultValue={exerciseResult.sets[0].set} style={{ width: 400, lineHeight: '30px', fontSize: '1.4rem', border: '1px solid black', borderRadius: '5px', height: '35px', padding: 0 }} onChange={setSelectedSet}> */}
                {/*  {exerciseResult.sets.map((set, index) => ( */}
                {/*    <Select.Option key={index} value={set.set}>#{set.set} - {set.percent}% - {set.time}{'"'}</Select.Option> */}
                {/*  ))} */}
                {/* </Select> */}
                <Collapse accordion defaultActiveKey={['0']} onChange={(e) => {
                  const set = exerciseResult.sets.find(s => s.set === (parseInt(e)));
                  setSelectedOption('0');
                  if (e === '0') {
                    axios.post(center.machine + ':3030/exerciseInfo', {
                      exercise: exerciseResult.sets,
                      command: 'all',
                      sensors: exerciseResult.sensors
                    });
                  } else {
                    axios.post(center.machine + ':3030/exerciseInfo', {
                      exercise: set,
                      command: 'sector',
                      sensors: exerciseResult.sensors
                    });
                  }
                }} style={{ width: '100%' }}>
                  <Panel key={0} header={capitalize(t('resume'))}>
                    <>TOTAL</>
                  </Panel>
                  {exerciseResult.sets.map((set, index) => (
                    <Panel key={index + 1} header={`#${set.set} -${set.percent} % -${set.time}${'"'}`}>
                      <>{exerciseResult && renderSetInfo(exerciseResult.sensors, set.set, exerciseResult)}</>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            )}
            {/* {exerciseResult && selectedSet && renderSetInfo(exerciseResult.sensors, selectedSet, exerciseResult)} */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {/* <Button className='basicButton' disabled={true} style={{ color: 'black', fontSize: 32, margin: 0, height: '100%' }} onClick={() => { setFinishedExercise(false); }}>{capitalize(t('repetir'))}</Button> */}
            <StartProgramButton program={selectedProgram} account={account} theme={center.theme} centerId={params.center} handleStartSession={handleStartSession} handleCreateProgramSession={handleCreateProgramSession} navigate={navigate} isometrics={true} noContinue={true} handleStopExercise={handleStopExercise}/>
            <button className={`basicButton${center.theme}`} onClick={ async () => {
              setFinishedExercise(false);
              await handleNextExercise();
              setInter(true);
            }}>{capitalize(t('continue'))}</button>
          </div>
        </div>
      </div>
    </>);
  }

  if (exerciseInProgress) {
    return (<>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ height: '100vh' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Button className={`basicButton${center.theme}`} disabled={true} style={{ color: 'black', fontSize: 32, margin: 0, height: '100%' }} onClick={() => { setFinishedExercise(false); }}>{capitalize(t('exerciseInProgress'))}</Button>
          </div>
        </div>
      </div>
    </>);
  }

  if (openSession && selectedExercise) {
    console.log('selectedProgram', selectedProgram);
    const done = selectedProgram.done && selectedProgram.done.find(d => d === selectedExercise._id);
    console.log('done', done);
    return (
      <>
        <div className={`background-main${center.theme}`} onClick={handleClick}>
          <div className={`background-sub${center.theme}`} style={{ minHeight: '100vh' }}>
            <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                <div onClick={() => {
                  setOpenSession(false);
                  // navigate(`/training/${params.center}/${params.account}`);
                }} style={{ padding: 5, border: '1px solid black', borderRadius: 6, marginLeft: 5, marginTop: 5, backgroundColor: 'gray', color: 'white', minWidth: 35, textAlign: 'center' }}><LeftOutlined /></div>
                <div onClick={() => {
                  axios.post(center.machine + ':3030/tare').then(() => {
                    setTare('OK');
                  });
                }} style={{ padding: 5, border: '1px solid black', borderRadius: 6, marginLeft: 5, marginTop: 5, backgroundColor: tare === 'OK' ? 'green' : 'gray', color: 'white' }}>Tare {tare}</div>
              </div>
              <Button onClick={() => navigate(-1)}
                style={{ height: '100%', border: 0, display: 'flex', alignItems: 'center' }} ghost ><span style={{ fontSize: 16, color: '#000' }}>
                  {account?.name} {account?.surname} ({account?.nif.slice(0, 3)}*****)
                </span><LogoutOutlined style={{ fontSize: 24, color: '#000' }} /></Button>
            </div>
            {/* <StyleRoot> */}
            {/* <div style={[{ height: programsHeight }, programsAnimation]}> */}
            <div style={{ fontSize: 22, marginBottom: 10, textAlign: 'center', color: '#000' }}>
              {capitalize(selectedExercise.name)}
            </div>
            <div className={'exercise-info'} style={{ fontSize: isMobile ? 14 : 20 }}>
              <div>
                {capitalize(t('chair'))}: {selectedExercise.chair}<br/>
                {capitalize(t('backChair'))}: {selectedExercise.back}<br/>
                {capitalize(t('sensors'))}: {selectedExercise.sensors}<br/>
                {capitalize(t('mode'))}: {selectedExercise.mode}<br/>
              </div>
              <div>
                {capitalize(t('apost'))}: {selectedExercise.ap}<br/>
                {capitalize(t('lm'))}: {selectedExercise.lm}<br/>
                {capitalize(t('lg'))}: {selectedExercise.lg}<br/>
                {capitalize(t('handleVert'))}: {selectedExercise.handleVert}<br/>
                {capitalize(t('handleHoriz'))}: {selectedExercise.handleHoriz}<br/>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, fontSize: isMobile ? 24 : 32 }}>
              <button className={`basicButton${center.theme}`} style={{ fontSize: isMobile ? 24 : 32, height: '100%', margin: 0 }} onClick={() => {
                handleRemoveSet();
              }}>-</button><div style={{ marginRight: 15, marginLeft: 15, width: 140, textAlign: 'center' }}>{setsNumber} Reps</div><button className={`basicButton${center.theme}`} style={{ fontSize: isMobile ? 24 : 32, height: '100%', margin: 0 }} onClick={() => {
                handleAddSet();
              }}>+</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, fontSize: isMobile ? 24 : 32 }}>
              {sets.map((set, index) => {
                return (
                  <Set key={index} set={set} center={center} isMobile={isMobile} handleModifySet={handleModifySet}/>
                );
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 25, width: '100%' }}>
              <button className={`basicButton${center.theme}`} style={{ marginBottom: 20, background: done && 'green', color: done && 'white' }}onClick={() => {
                if (!done) {
                  handleStartExercise();
                }
              }}>{done ? t('done').toUpperCase() : t('start').toUpperCase()}</button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className={`basicButton${center.theme}`} style={{ marginBottom: 20 }}onClick={() => {
                const exerciseIndex = selectedProgram.exercises.findIndex(exercise => exercise._id === selectedExercise._id);
                if (exerciseIndex > 0) {
                  setSelectedExercise(selectedProgram.exercises[exerciseIndex - 1]);
                  if (selectedProgram.exercises[exerciseIndex - 1].setNumber && selectedProgram.exercises[exerciseIndex - 1].setNumber > 0) {
                    setSetsNumber(selectedProgram.exercises[exerciseIndex - 1].setNumber);
                    const sets = [];
                    for (let i = 0; i < selectedProgram.exercises[exerciseIndex - 1].setNumber; i++) {
                      sets.push({
                        set: selectedProgram.exercises[exerciseIndex - 1].sets[i].key + 1,
                        time: selectedProgram.exercises[exerciseIndex - 1].sets[i].time,
                        percent: selectedProgram.exercises[exerciseIndex - 1].sets[i].percent
                      });
                    }
                    setSets(sets);
                  } else {
                    handleResetSets();
                  }
                  setTare('');
                  // setInter(false);
                }
              }}>{t('previous').toUpperCase()}</button>
              <button className={`basicButton${center.theme}`} style={{ marginBottom: 20 }}onClick={() => {
                const exerciseIndex = selectedProgram.exercises.findIndex(exercise => exercise._id === selectedExercise._id);
                if (exerciseIndex < selectedProgram.exercises.length - 1) {
                  setSelectedExercise(selectedProgram.exercises[exerciseIndex + 1]);
                  if (selectedProgram.exercises[exerciseIndex + 1].setNumber && selectedProgram.exercises[exerciseIndex + 1].setNumber > 0) {
                    setSetsNumber(selectedProgram.exercises[exerciseIndex + 1].setNumber);
                    const sets = [];
                    for (let i = 0; i < selectedProgram.exercises[exerciseIndex + 1].setNumber; i++) {
                      sets.push({
                        set: selectedProgram.exercises[exerciseIndex + 1].sets[i].key + 1,
                        time: selectedProgram.exercises[exerciseIndex + 1].sets[i].time,
                        percent: selectedProgram.exercises[exerciseIndex + 1].sets[i].percent
                      });
                    }
                    setSets(sets);
                  } else {
                    handleResetSets();
                  }
                  setTare('');
                  // setInter(false);
                }
              }}>{t('next').toUpperCase()}</button>
            </div>
            {/* </div> */}
            {/* </StyleRoot> */}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ minHeight: '100vh' }}>
          <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate(-1)}
              style={{ height: '100%', border: 0, display: 'flex', alignItems: 'center' }} ghost ><span style={{ fontSize: 16, color: '#000' }}>
                {account?.name} {account?.surname} ({account?.nif.slice(0, 4)}****)
              </span><LogoutOutlined style={{ fontSize: 24, color: '#000' }} /></Button>
          </div>
          <StyleRoot>
            <div style={[{ display: 'flex', alignItems: programsPosition, justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', height: programsHeight, width: '100%' }, programsAnimation]}>
              {programs?.map(program => renderProgram(program))}
            </div>
            {openProgram && showOpenProgram && (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', height: '70vh' }}>
              {renderExercises(openProgram)}
            </div>)}
            {weightHistory && <WeightControl weightModalVisible={weightModalVisible} setWeightModalVisible={setWeightModalVisible} weightHistory={weightHistory}/>}
            {openProgram && <ProgressControl program={openProgram._id} progressControlModalVisible={progressControlModalVisible} handleCloseProgressControlModal={handleCloseProgressControlModal} />}
            {messageModalVisible && <FullScreenMessageModal messageModalVisible={messageModalVisible} setMessageModalVisible={setMessageModalVisible} message={messageModal}/>}
          </StyleRoot>
        </div>
      </div>
    </>
  );
};
export default ClientPrograms;
