import { methodGet, methodPatch, methodPost, methodDelete } from './api/methods';

export const getAllProgramSessions = (token) => {
  return methodGet('programsession/all', '', token);
};

export const getProgramSessionById = (id, token) => {
  return methodGet('programsession/' + id, '', token);
};

export const getProgramSessionByQuery = (req, token) => {
  return methodGet('programsession', req, token);
};

export const getProgramSessionWithExerciseSessions = (req, token) => {
  return methodGet('programsession/exercisesession', req, token);
};

export const getProgramSessionByQueryWithExercises = (req, token) => {
  return methodGet('programsession/exercises', req, token);
};

export const createProgramSession = (data, token) => {
  return methodPost('programsession/create', data, token);
};

export const patchProgramSession = (id, data, token) => {
  return methodPatch('programsession/update?id=' + id, data, token);
};

export const getSessionPdf = (id, token) => {
  return methodGet('programsession/downloadpdf?id=' + id, '', token);
};

export const deleteProgramSession = (id, token) => {
  return methodDelete('programsession/delete?id=' + id, '', token);
};
