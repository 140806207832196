import React, { useEffect, useState } from 'react';
import {
  createProgramSession,
  getProgramSessionByQuery,
  patchProgramSession,
  deleteProgramSession
} from '../../services/programsession.service';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/utils';

const StartProgramButton = (props) => {
  // const { user } = useContext(Auth);
  const [programSession, setProgramSession] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    loadProgramSession(props.program._id);
    // }
  }, [props.program._id]);

  const loadProgramSession = async (programId) => {
    setLoading(true);
    const { status, data } = await getProgramSessionByQuery({ program: programId, isDeleted: false });
    if (status === 200) {
      setProgramSession(data.data[data.data.length - 1]);
    } else {
      setProgramSession(null);
    }
    setLoading(false);
  };

  const startProgramSession = async () => {
    setLoading(true);
    const data = {
      isActive: true,
      account: props.account._id,
      program: props.program._id,
      center: props.centerId
    };
    const programSession = await createProgramSession(data);
    console.log('PROPS', props);
    props.handleCreateProgramSession(programSession);
    await loadProgramSession(props.program._id);
    props.handleStartSession();
    setLoading(false);
  };

  const continueProgramSession = async () => {
    setLoading(true);

    props.handleStartSession();
    setLoading(false);
  };

  const stopProgramSession = async () => {
    setLoading(true);
    const data = {
      isActive: false,
      isDeleted: true,
      closed: new Date()
    };
    await patchProgramSession(programSession._id, data);
    loadProgramSession(props.program._id);
    props.handleStopExercise();
    setLoading(false);
  };

  const deleteThisProgramSession = async () => {
    setLoading(true);
    await deleteProgramSession(programSession._id);
    loadProgramSession(props.program._id);
    setLoading(false);
  };

  return (
    <>
      {programSession?.closed
        ? (
          <button className={`basicButton${props.theme}`} disabled={loading} style={{ margin: 15 }} onClick={() => {
            console.log('archive');
          }}>{capitalize(t('arvhive'))}</button>
        )
        : (
          <>
            <button className={`basicButton${props.theme}`} disabled={loading} style={{ margin: 15 }} onClick={() => {
              if (programSession?.isActive) stopProgramSession();
              else startProgramSession();
            }}>{programSession?.isActive ? capitalize(t('finish')) : capitalize(t('start'))}</button>
            {programSession?.isActive && !props.noContinue && (
              <button className={`basicButton${props.theme}`} disabled={loading} style={{ margin: 15 }} onClick={() => {
                continueProgramSession();
              }}>{capitalize(t('continue'))}</button>
            )}
          </>
        )}
    </>
  );
};

export default StartProgramButton;
