export default {
  weightBefore: 'Antes de empezar, introduce tu peso',
  next: 'siguiente',
  weight: 'peso',
  ready: 'preparate',
  finish: 'finalizar',
  weightControl: 'Control de peso',
  progress: 'progreso',
  nextDevice: 'Siguiente dispositivo',
  settings: 'configuración',
  tulShort: 'TUL Corto',
  tulMedium: 'TUL Medio',
  tulLong: 'TUL Largo',
  lastSession: 'Última sesión',
  noLastSession: 'No hay sesión anterior',
  firstTimeExercise: 'Es la primera vez <br/>que el usuario hace este ejercicio. <br/>Por favor, configuralo.',
  reps: 'reps',
  start: 'comenzar',
  time: 'tiempo',
  exerciseCompleted: 'Ejercicio completado',
  ns: 'ns',
  nf: 'nf',
  mt: 'mt',
  ap: 'ap',
  assistedReps: 'Rep. Asistidas',
  discountReps: 'Descontar Rep.',
  saveResults: 'Guardar resultados',
  retry: 'Reintentar',
  archive: 'archivar',
  login: 'acceder',
  logout: 'salir',
  home: 'inicio',
  user: 'usuario',
  password: 'contraseña',
  signIn: 'entrar',
  signOut: 'salir',
  enter: 'entrar',
  centers: 'centros',
  organizers: 'organizadores',
  users: 'usuarios',
  createNewOrganizer: 'Crear nuevo organizador',
  createNewCenter: 'Crear nuevo centro',
  confirmDeleteCenter: '¿Estás seguro de que quieres eliminar este centro?',
  centerDeleted: 'centro eliminado',
  confirmDeleteOrganizer: '¿Estás seguro de que quieres eliminar este organizador?',
  organizerDeleted: 'organizador eliminado',
  name: 'nombre',
  email: 'email',
  phone: 'teléfono',
  nif: 'nif',
  createOrganizer: 'Crear organizador',
  createCenter: 'Crear centro',
  surname: 'apellidos',
  birthdate: 'fecha de nacimiento',
  position: 'cargo',
  sessions: 'sesiones',
  address: 'dirección',
  cif: 'cif',
  description: 'descripción',
  plan: 'plan',
  organizer: 'organizador',
  myCenter: 'mi centro',
  trainers: 'entrenadores',
  devices: 'dispositivos',
  exercises: 'ejercicios',
  booking: 'reservas',
  activeSessions: 'sesiones activas',
  earnings: 'ganancias',
  mainScreen: 'pantalla principal',
  goToUrl: 'ir a la URL',
  copyUrl: 'URL copiada correctamente',
  settingsCode: 'código de configuración',
  weightMeasure: 'medida de peso',
  highMeasure: 'medida de altura',
  createNewTrainer: 'Crear nuevo entrenador',
  createTrainer: 'Crear entrenador',
  isRequired: 'necesario',
  editOrganizer: 'Editar organizador',
  editCenter: 'Editar centro',
  editTrainer: 'Editar entrenador',
  confirmDeleteTrainer: '¿Estás seguro de que quieres eliminar este entrenador?',
  trainerDeleted: 'Entrenador eliminado',
  no: 'no',
  yes: 'sí',
  createNewUser: 'Crear nuevo usuario',
  createUser: 'Crear usuario',
  editUser: 'Editar usuario',
  confirmDeleteUser: '¿Estás seguro de que quieres eliminar este usuario?',
  userDeleted: 'Usuario eliminado',
  credits: 'créditos',
  edit: 'editar',
  history: 'historial',
  programs: 'programas',
  created: 'creado',
  createNewHistory: 'Crear nuevo registro de historial',
  createHistory: 'Crear registro de historial',
  historyRecord: 'Registro de historial',
  editHistory: 'Editar registro de historial',
  histories: 'historiales',
  upload: 'subir',
  actions: 'acciones',
  confirmDeleteHistory: '¿Estás seguro de que quieres eliminar este registro de historial?',
  historyDeleted: 'Registro de historial eliminado',
  createNewProgram: 'Crear nuevo programa',
  createProgram: 'Crear programa',
  editProgram: 'Editar programa',
  confirmDeleteProgram: '¿Estás seguro de que quieres eliminar este programa?',
  programDeleted: 'Programa eliminado',
  isActive: 'activo',
  active: 'activo',
  availableExercices: 'ejercicios disponibles',
  selectedExercises: 'ejercicios seleccionados',
  true: 'si',
  false: 'no',
  createNewSetting: 'Crear nueva configuración',
  createSetting: 'Crear configuración',
  editSetting: 'Editar configuración',
  confirmDeleteSetting: '¿Estás seguro de que quieres eliminar esta configuración?',
  settingDeleted: 'Configuración eliminada',
  exercise: 'ejercicio',
  fiber: 'fibra',
  concentric: 'concentrico',
  eccentric: 'excentrico',
  squeeze: 'squeeze',
  createNewDevice: 'Crear nuevo dispositivo',
  createDevice: 'Crear dispositivo',
  editDevice: 'Editar dispositivo',
  confirmDeleteDevice: '¿Estás seguro de que quieres eliminar este dispositivo?',
  deviceDeleted: 'Dispositivo eliminado',
  minWeight: 'peso mínimo',
  maxWeight: 'peso máximo',
  identifier: 'identificador',
  createNewExercise: 'Crear nuevo ejercicio',
  createExercise: 'Crear ejercicio',
  editExercise: 'Editar ejercicio',
  confirmDeleteExercise: '¿Estás seguro de que quieres eliminar este ejercicio?',
  exerciseDeleted: 'Ejercicio eliminado',
  bookingData: 'Datos de reserva',
  startDate: 'fecha de inicio',
  endDate: 'fecha de fin',
  startTime: 'inicio',
  endTime: 'fin',
  queue: 'cola',
  delete: 'eliminar',
  create: 'crear',
  buyCredits: 'comprar créditos',
  myProfile: 'mi perfil',
  see: 'ver',
  evolution: 'evolución',
  date: 'fecha',
  free: 'libre',
  booked: 'reservado',
  hasQueue: 'tiene cola',
  inQueue: 'en cola',
  full: 'lleno',
  book: 'reservar',
  day: 'día',
  editBookingData: 'editar datos de reserva',
  cancelBook: 'cancelar reserva',
  leaveQueue: 'dejar cola',
  personsInQueue: 'personas en cola',
  persons: 'personas',
  logOut: 'Log Out',
  selectPlan: 'Seleccionar plan',
  selectOrganizer: 'Seleccionar organizador',
  noAttachments: 'No hay archivos adjuntos',
  attachments: 'Archivos adjuntos',
  noActive: 'no activo',
  noExercises: 'no hay ejercicios',
  recommendedWeight: 'peso recomendado',
  selectExercise: 'Selecciona ejercicio',
  id: 'id',
  center: 'centro',
  trainer: 'entrenador',
  credit: 'crédito',
  buy: 'comprar',
  detail: 'detalle',
  details: 'detalles',
  occupied: 'ocupado',
  notEnoughCredits: 'no dispones de créditos sufientes',
  language: 'idioma',
  castilian: 'castellano',
  english: 'inglés',
  assignSlots: 'asignar turnos',
  templates: 'plantillas',
  selectTemplateForDay: 'Selecciona una plantilla para el día seleccionado',
  selectTemplate: 'Selecciona plantilla',
  setTemplate: 'Establecer plantilla',
  dayHasEvents: 'El día seleccionado podría tener eventos, ¿estás seguro de que quieres añadir esta plantilla?',
  removeUserBook: '¿Estás seguro de que quieres eliminar este usuario de la reserva?',
  type: 'tipo',
  bookings: 'reservas',
  slots: 'turnos',
  createNewTemplate: 'crear nueva plantilla',
  createTemplate: 'crear plantilla',
  editTemplate: 'editar plantilla',
  confirmDeleteTemplate: '¿Estás seguro de que quieres eliminar esta plantilla?',
  templateDeleted: 'plantilla eliminada',
  config: 'configuración',
  back: 'volver',
  search: 'buscar',
  addCredits: 'añadir créditos',
  saveChanges: 'guardar cambios',
  lastSessions: 'últimas sesiones',
  somethingWrong: 'Algo ha ido mal',
  paymentSuccess: 'Pago realizado con éxito',
  paymentNotSuccess: 'El pago no se ha podido realizar o ha ocurrido algún imprevisto. Por favor, contacta con nosotros en el correo electrónico pagos@seifitness.com',
  paymentSuccessMessage: '¡Gracias por tu compra! Ya puedes utilizar tus créditos para hacer tu reserva.',
  actualCredits: 'disponibles',
  userSlots: 'huecos',
  transactions: 'transacciones',
  paid: 'pagado',
  accessCode: 'código de acceso',
  wrongAccessCode: 'código de acceso incorrecto',
  booksession: 'servicio',
  booksessions: 'servicios',
  createNewBooksession: 'crear nuevo servicio',
  createBooksession: 'crear servicio',
  editBooksession: 'editar servicio',
  selectTrainer: 'seleccionar entrenador',
  confirmDeleteBooksession: '¿Estás seguro de que quieres eliminar este servicio?',
  booksessionDeleted: 'servicio eliminado',
  theme: 'tema',
  selectTheme: 'seleccionar tema',
  dark: 'oscuro',
  light: 'claro',
  price: 'precio',
  total: 'total',
  transactionOk: 'transacción realizada con éxito',
  transactionError: 'transacción fallida',
  activeSession: 'sesión activa',
  changePassword: 'cambiar contraseña',
  oldPassword: 'contraseña antigua',
  newPassword: 'nueva contraseña',
  confirmPassword: 'repetir contraseña',
  passwordChanged: 'contraseña cambiada',
  passwordsDoNotMatch: 'las contraseñas no coinciden',
  incorrectPassword: 'contraseña incorrecta',
  passwordTooShort: 'la contraseña debe tener al menos 6 caracteres',
  resetPassword: 'restablecer contraseña',
  confirmResetPassword: '¿Estás seguro de que quieres restablecer la contraseña de este usuario?',
  passwordReset: 'contraseña restablecida',
  tilt: 'ROM',
  strength: 'fuerza',
  strengthTest: 'test de fuerza',
  confirmDeleteRow: '¿Estás seguro de que quieres eliminar esta fila?',
  addRow: 'añadir fila',
  createNewStrengthTest: 'crear nuevo test de fuerza',
  createStrengthTest: 'crear test de fuerza',
  editStrengthTest: 'editar test de fuerza',
  confirmDeleteStrengthTest: '¿Estás seguro de que quieres eliminar este test de fuerza?',
  strengthTestDeleted: 'test de fuerza eliminado',
  percent: 'porcentaje',
  noLimit: 'sin límite',
  set: 'serie',
  sets: 'series',
  isometric: 'time static',
  hist: 'hist',
  lastSet: 'última serie',
  maxExercises10: 'no puedes añadir más de 10 ejercicios',
  assignWeight: 'asignar peso',
  chair: 'asiento',
  backChair: 'respaldo',
  sensors: 'sensores',
  mode: 'modo',
  both: 'juntos',
  separated: 'separados',
  left: 'izquierdo',
  right: 'derecho',
  tension: 'tensión',
  compression: 'compresión',
  apost: 'anteroposterior',
  lm: 'lateromedial',
  lg: 'longitudinal',
  handleVert: 'maneral vertical',
  handleHoriz: 'maneral horizontal',
  exerciseInProgress: 'ejercicio en progreso',
  resume: 'resumen',
  displayHighestPeak: 'mostrar pico más alto en pantalla',
  displayLowestPeak: 'mostrar pico más bajo en pantalla',
  displayeLeftSensor: 'mostrar sensor izquierdo en pantalla',
  displayRightSensor: 'mostrar sensor derecho en pantalla',
  displayBothSensors: 'mostrar ambos sensores en pantalla',
  maxStrength: 'fuerza máxima',
  minStrength: 'fuerza mínima',
  avgStrength: 'fuerza media'
};
